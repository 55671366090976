<template>
  <div class="base-list-page">
    <el-form
      :model="dataForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input v-model="dataForm.name" placeholder="公司名称" clearable />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:company:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:company:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      ref="dataList"
      @select-all="handleSelectAll"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="parentCompanyName"
        header-align="center"
        align="center"
        label="上级公司"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="公司名称"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        header-align="center"
        align="center"
        label="公司地址"
      >
      </el-table-column>
      <el-table-column
        prop="isMark"
        header-align="center"
        align="center"
        label="是否记账"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isMark == 0" type="success" size="mini"
            >不记账</el-tag
          >
          <el-tag v-else-if="scope.row.isMark == 1" type="warning" size="mini"
            >记账</el-tag
          >
          <el-tag v-else type="info" size="mini">未知</el-tag>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="discountRate"-->
      <!--        header-align="center"-->
      <!--        align="center"-->
      <!--        label="折扣率"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          <p>{{ scope.row.discountRate }}%</p>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <el-table-column
        header-align="center"
        align="center"
        width="220"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:company:info')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:company:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('tc:company:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id, scope.row.name)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './company-add-or-update';
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],
  data() {
    return {
      dataForm: {
        name: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList(1);
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$api({
        url: '/tc/company/query',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          name: this.dataForm.name,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.list.records;
            this.totalPage = data.list.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id, name) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      let names = name
        ? [name]
        : this.dataListSelections.map((item) => {
            return '"' + item.name + '"';
          });
      this.$confirm(
        `确定对${names.join(',')}进行${id ? '删除' : '批量删除'}操作?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          this.$http({
            url: '/tc/company/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
